/* ==========================================================================
   Reset
   ========================================================================== */

/* Reset Modified from Normalize.css */

/* Base Reset */

* {
    margin: 0;
    padding: 0;
}

*, *:before, *:after { box-sizing: inherit; }


html {
    min-height: 100%;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    font-size: 62.5%; // Make it easy to calculate rems to px
}

body {
    @include ff--sans-serif(400);
    -webkit-font-smoothing: antialiased;
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section {
    display: block;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 400;
    color: $c-base__02;
}


/* Media */
audio, canvas, progress, video {
    display: inline-block;
    vertical-align: baseline;
}

audio:not([controls]) {
    display: none;
    height: 0;
}

/* Images */

[hidden], template { display: none; }

img {
    max-width: 100%;
    font-style: italic;
    vertical-align: middle;
    border: 0;
}

svg:not(:root) { overflow: hidden; }

svg {
    pointer-events: none;
    max-height: 100%
}


/* Links */

a {
    background-color: transparent;
    text-decoration: none;
    color: $c-accent__blue;
}

a:active, a:hover { outline: 0; }

/* Text Selectors */

abbr[title] { border-bottom: 1px dotted; }
b, strong   { font-weight: bold; }
i, em       { font-weight: italic; }

/* Content */

figure {
    margin: 0;
}


hr {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
    width: 100%;
    height: 1px;
    border: 0;
    background: #EFF1F3;
}

/* Code Blocks */

pre { overflow: auto; }

code, pre {

}
